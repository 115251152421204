export const convertUTCDateToLocalDate = (inputdate) =>{
    var newDate = new Date(inputdate.getTime()+inputdate.getTimezoneOffset()*60*1000);

    var offset = inputdate.getTimezoneOffset() / 60;
    var hours = inputdate.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}

export const debugLog = (logText) => {
    if (process.env.REACT_APP_CONSOLEDEBUG == 'true') {
        console.log(logText);
    }

}