import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import slider_0 from "../images/slider_0.png";
import slider_1 from "../images/slider_1.png";
import slider_2 from "../images/slider_2.png";
import slider_3 from "../images/slider_3.png";
import slider_4 from "../images/slider_4.png";
import slider_5 from "../images/slider_5.png";
function BAVRisk(props) {
  const buttons = ["SEHR TIEF", "TIEF", "MODERAT", "HOCH", "SEHR HOCH"];
  const [button, setButton] = useState();
  const [img, setImage] = useState(slider_0);
  const [value, setValue] = useState(
    props.response.value_1 ? props.response.value_1 : ""
  );
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newButton: string
  ) => {
    var sel = buttons.indexOf(newButton) + 1;
    setButton(newButton);
    setValue(sel);
    var img;
    switch (sel) {
      case 0:
        img = slider_0;
        break;
      case 1:
        img = slider_1;
        break;
      case 2:
        img = slider_2;
        break;
      case 3:
        img = slider_3;
        break;
      case 4:
        img = slider_4;
        break;
      case 5:
        img = slider_5;
        break;
    }
    setImage(img);
    if (typeof props.callback == "function" && event !== null) {
      props.callback(props, sel);
    }
  };
  useEffect(() => {
    var newValue = buttons[value - 1];
    handleChange(null, newValue);
  }, []);

  return (
    <Container style={{ margin: "0px" }}>
      <Container style={{ margin: "0px" }}>
        <div style={{ margin: "0px" }}>
          <img src={img} alt={slider_0} style={{ width: "100%" }} />
        </div>
      </Container>
      <Container maxWidth="lg">
        <ToggleButtonGroup
          color="primary"
          value={button}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          size="medium"
          sx={{ width: "100%", pt: 1 }}
        >
          {buttons.map((_btn, _index) => (
            <ToggleButton key={_index} value={_btn} sx={{ width: "20%" }}>
              {_btn}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Container>
    </Container>
  );
}
export default BAVRisk;
