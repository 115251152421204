import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Typography } from "@mui/material";

function BAVTextArea(props) {
  
  
   
  const [textValue, setTextValue] = useState(
    props.responses[0].value_1 ? props.responses[0].value_1 : ""
  );
    
    
  function changeText(e) {
    setTextValue(e.target.value);
  }

  function changeSelection(e, response) {
    if (typeof props.callback == "function") {
      props.callback(props, e.target.value, response);
    }
  }


  function isValid(){
      var regExp = /[a-zA-Z0-9]/g;
      var valid = !(props.required && (textValue == '')) && textValue.length > 2 && regExp.test(textValue);
      props.validation(valid);
      return valid;
  }

  function getBemerkungText(){
      if (props.type == 1){
          return "Wenn Sie eine Anlageberatung wünschen und spezifische Präferenzen haben, müssen diese hier erfasst werden";
      } else if (props.type == 2) {
        return "Die von Ihnen gewählte Anlagestrategie weicht von der empfohlene Anlagestrategie der Bank ab, bitte begründen Sie diese Abweichung";
      }
  }

  return (
    <>
      <Typography variant="body1" ml={0} mt={1}>
      <textarea
          rows={4}
          cols={60}
          style={{ marginTop: "10px", marginLeft: "12px"}}
          key={props.responses[0].questionId + props.responses[0].responseId}
          value={textValue}
          onBlur={(e) => changeSelection(e, props.responses[0])}
          onChange={(e) => changeText(e)}
        />
      </Typography>
       <div style={{ color: "red", fontStyle: "italic", marginLeft: "15px" }}>{isValid()==false?"Das Bemerkungsfeld darf nicht leer sein":""}</div>
    </>
  );
}
export default BAVTextArea;
