import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Typography } from "@mui/material";
import { debugLog } from "../utils/Util";

function BAVRadioGroupESG(props) {
  const [disabled, setDisabled] = useState();
  function changeText(e) {
    setTextValue(e.target.value);
  }

    function changeSelection(e, response) {
      debugLog('blur');
      setDisabled(true);
    if (typeof props.callback == "function") {
      props.callback(props, e.target.value, response, e.target);
    }
    setDisabled(false);
  }

  const [textValue, setTextValue] = useState(
    props.responses[2] ? props.responses[2].value_1 ? props.responses[2].value_1 : "" : ""
  );
  function renderRadio() {
    const objs: JSX.Element[] = [];
    if (props.responses.length > 0) {
      for (let i = 0; i < props.responses.length; i++) {
        if (i < 2) {
          objs.push(
            <FormControlLabel
              value={props.responses[i].responseId}
              key={
                props.responses[i].questionId +
                "_" +
                props.responses[i].responseId +
                "_" +
                props.responses[i].entryDetailId
              }
              control={
                <Radio
                  id={"bavRadioCond" + props.responses[i].responseId + "_" + i}
                  sx={{
                    "&.Mui-checked": {
                      color: "#006e58",
                    },
                  }}
                  onChange={(e) => changeSelection(e, props.responses[i])}
                  checked={
                    props.responses[i].responseId == props.responses[i].value_1
                  }
                  disabled={disabled}
                />
              }
              label={props.responses[i].response}
            />
          );
        }
      }
    }
    return objs;
  }

  function renderTextarea() {
    if (props.responses.length > 0) {
      for (let i = 0; i < props.responses.length; i++) {
        if (i == 2) {
          return (
            <>
              <Typography variant="body1" ml={1} mt={1}>
                {props.responses[i].response}
              </Typography>
              <Typography variant="body1" ml={1} mt={1}>
                <textarea
                  rows={4}
                  cols={60}
                  style={{ marginTop: "10px", marginLeft: "12px" }}
                  //key={props.responses[i].questionId+props.responses[i].responseId+'txt'}
                  value={textValue}
                  onBlur={(e) => changeSelection(e, props.responses[i])}
                  onChange={(e) => changeText(e)}
                />
              </Typography>
            </>
          );
        }
      }
    }
  }

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="bav-radio-buttons-groupCond-label"
          defaultValue=""
          name="bav-radio-buttons-groupCond"
          className="bavRadioGroupCond"
          style={{ paddingTop: "10px", paddingLeft: "12px" }}
        >
          {renderRadio()}
        </RadioGroup>
      </FormControl>
      {renderTextarea()}
    </>
  );
}
export default BAVRadioGroupESG;
