import Home from "./components/Home";
import MainPage from "./components/MainPage";
import CustomerDetail from "./components/CustomerDetail";
import CustomerProfile from "./components/CustomerProfile";
import Login from "./components/Login";
import Logout from "./components/Logout";
import BAVReport from "./components/BAVReport";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/mainpage",
    element: <MainPage />,
  },
  {
    path: "/customerDetail",
    element: <CustomerDetail />,
  },
  {
    path: "/customerProfile",
    element: <CustomerProfile />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/BAVReport",
    element: <BAVReport />,
  },
  
];

export default AppRoutes;
