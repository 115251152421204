import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import BavLogo from "../images/bav_logo_bankavera_white.png";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          container
        >
          <NavbarBrand tag={Link} to="/">
            <img src={BavLogo}></img>
          </NavbarBrand>
          <NavbarBrand tag={Link} to="/">
            
          </NavbarBrand>
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!this.state.collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/mainpage">
                  Profilierung
                </NavLink>
              </NavItem>
{/*              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light"
                  to="https://localhost:7171/swagger"
                >
                  Swagger
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} className="text-light" to="/login">
                  Login
                </NavLink>
              </NavItem>
 */}
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/logout">
                  Logout
                </NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
