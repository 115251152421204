import * as React from "react";
import { useState, useEffect, useContext } from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  deDE,
  GridToolbar,
} from "@mui/x-data-grid";
import { FocusStyleManager } from "@blueprintjs/core";
import { Container, Row, Col } from "reactstrap";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useMsal,
} from "@azure/msal-react";
import {
  Button,
  IconButton,
  Modal,
  Box,
  Typography,
  ButtonGroup,
  TextField,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Paper,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Switch
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, useLocation } from "react-router-dom";
import CustomerSearchDialog from "./CustomerSearchDialog";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query";
// using node-style package resolution in a CSS file:
import "normalize.css";
import "./MainPage.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { BAVLogin } from "./BAVLogin";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import BAVButton from "./BAVButton";
import { debugLog } from "../utils/Util";


const queryClient = new QueryClient();

export default function MainPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <CreateMainPage />
    </QueryClientProvider>
  );
}

function CreateMainPage() {
  FocusStyleManager.onlyShowFocusOnTabs();
  const navigate = useNavigate();
  const location = useLocation();
  const [options, setOptions] = useState();
  const [token, setToken] = useState();
  const initToken = async () => {
    const ltoken = await getUserToken();
    setToken(ltoken);
    const headers = new Headers();
    const bearer = `Bearer ${ltoken.accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    setOptions({ headers: headers });
  };

  const reInitToken = new Promise((resolve, reject) => {
    if (token && token.expiresOn < Date.now()) {
        debugLog('Start initToken');
        initToken()
            .then((res) => {
                debugLog('Finished initToken');
                resolve('initToken');
             })
     } else {
        debugLog('tokenValid');
        resolve('tokenValid');
     }
  });

  const initializeSearchCriteria = () => {
    return JSON.parse(localStorage.getItem("searchCriteria")) ?? "";
  };

  const initializeSearchData = () => {
    return JSON.parse(localStorage.getItem("searchData")) ?? [];
  };
  const initializeAdivsorData = () => {
    return JSON.parse(localStorage.getItem("adivsorData")) ?? [];
  };

  function clearStorage() {
    let session = sessionStorage.getItem("register");
    if (session == null) {
      localStorage.removeItem("searchCriteria");
      localStorage.removeItem("searchData");
      localStorage.removeItem("advisorData");
      setCustomerdata([]);
      setSearchCriteria("");
    }
    sessionStorage.setItem("register", 1);
  }

  const [kundendaten, setCustomerdata] = useState(initializeSearchData());
  const [loading, setLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(
    initializeSearchCriteria()
  );
  const [advisordata, setAdvisordata] = useState(initializeAdivsorData());
  const [selectedAdvisor, setSelectedAdvisor] = useState("");
  const [onlyActive, setOnlyActive] = useState(1);

  useEffect(() => {
    initToken();
  }, []);
  useEffect(() => {
    clearStorage();
    if (options) {
      const getAdvisorData = async () => {
          await reInitToken;
          const res = await fetch("/advisors", options);
          const response = await res.json();
        setAdvisordata(response);
      };
      getAdvisorData();
      // Wenn reload Flag gesetzt, dann Kunden  neu lesen
      if(location.state &&  location.state.reload){
        location.state.reload = 0;
        readCustomerData(searchCriteria, selectedAdvisor, onlyActive);
      }
    }
  }, [options]);

  useEffect(() => {
    localStorage.setItem("searchCriteria", JSON.stringify(searchCriteria));
  }, [searchCriteria]);

  useEffect(() => {
    localStorage.setItem("searchData", JSON.stringify(kundendaten));
  }, [kundendaten]);

  useEffect(() => {
    localStorage.setItem("advisorData", JSON.stringify(advisordata));
  }, [advisordata]);

  function readCustomerData(searchCriteria, selectedAdvisor, active) {
      var kdlnr = "";
      if (selectedAdvisor) {
        for (var i = 0; i < advisordata.length; i++) {
          if (advisordata[i].benutzer == selectedAdvisor) {
            kdlnr = advisordata[i].kdLnr;
            break;
          }
        }
      }
      setLoading(true);
      // Wenn nur eigene, dann noch eigene KundenLnr mitgeben own=214234234
      var act = active?1:0;
      kdlnr = kdlnr ? "?kdlnrberat=" + kdlnr +"&active="+act : "?active=" + act;
      var path = searchCriteria
        ? "/customers/search/" + searchCriteria + kdlnr
        : "/customers" + kdlnr ;
      reInitToken
          .then((res) => {
              debugLog('fetchCustomerData')
              return fetch(path, options)
                  .then((res) => res.json())
                  .then((data) => {
                      setCustomerdata(data);
                      setLoading(false);
                  })
                  .catch((error) => {
                  })
          });
    }


  function clickSearch() {
    readCustomerData(searchCriteria, selectedAdvisor, onlyActive);
  }

  function handleRowDoubleClick(params, me, det) {

    var kd = params.row;
    navigate("/customerDetail", { state: { kunde: kd } });
  }

  function handleOwnCustomers(e) {
    setOwnCustomerOnly(e.target.checked);
  }
  function handleClearClick(e) {}

  function handleSwitchActive(e) {
      setOnlyActive(e.target.checked);
  }

  const searchButton = (
    <BAVButton variant="contained" onClick={clickSearch}>
      Suchen
    </BAVButton>
  );

  const searchFieldProps = {
    endAdornment: (
      <InputAdornment position="end">
        <SearchIcon />
      </InputAdornment>
    ),
  };
  const columns: GridColDef[] = [
    {
      field: "kdNr",
      headerName: "Kunden-Nr",
      headerClassName: "BAVColumnHeader",
      width: 120,
    },
    {
      field: "surname",
      headerName: "Vorname",
      headerClassName: "BAVColumnHeader",
      width: 110,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "BAVColumnHeader",
      width: 180,
    },
    {
      field: "city",
      headerName: "Ort",
      headerClassName: "BAVColumnHeader",
      width: 130,
    },
    {
      field: "birthdate",
      headerName: "Geb.-Datum",
      headerClassName: "BAVColumnHeader",
      width: 120,
      valueFormatter: ({ value }) =>
        value &&
        new Date(value).toLocaleString("de-CH", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "createDate",
      headerName: "Erstellt",
      headerClassName: "BAVColumnHeader",
      width: 140,
      valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
    },
    {
      field: "createdBy",
      headerName: "Erstellt von",
      headerClassName: "BAVColumnHeader",
      width: 160,
    },
    {
      field: "changeDate",
      headerName: "Geändert",
      headerClassName: "BAVColumnHeader",
      width: 140,
      valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
    },
    {
      field: "changedBy",
      headerName: "Geändert von",
      headerClassName: "BAVColumnHeader",
      width: 140,
      },
    {
        field: "kundenHauptTypCode",
        headerName: ""
    }
  ];

  return (
    <>
      <Container className="mainContainer">
        <Paper>
          <div className="pageContainer">
            <Container className="pt-3 BAVMainHeader" fluid>
              <Row>
                <Col >
                  <h5>Anlageberatungsprofile</h5>
                </Col>
                <Col  xs={4}>
                  <TextField
                    id="outlined-search"
                    label=""
                    variant="outlined"
                    size="small"
                    InputProps={searchFieldProps}
                    onChange={(e) => {
                      setSearchCriteria(e.target.value);
                    }}
                    value={searchCriteria}
                  />
                  {searchButton}
                </Col>
                <Col>
                  <FormControl sx={{ width: 200 }}>
                    <InputLabel
                      sx={{ fontSize: "0.750rem" }}
                      id="advisor_label"
                    >
                      Berater/in
                    </InputLabel>
                    <Select
                      labelId="advisor_label"
                      id="advisor"
                      value={selectedAdvisor}
                      onChange={(e) => setSelectedAdvisor(e.target.value!=null?e.target.value:'')}
                      sx={{ fontSize: "0.750rem" }}
                      size="small"
                    >
                      <MenuItem sx={{ fontSize: "0.750rem" }} key="">
                        Keine(r)
                      </MenuItem>
                      {advisordata.map((advisor) => (
                        <MenuItem
                          sx={{ fontSize: "0.750rem" }}
                          key={advisor.kdLnr}
                          value={advisor.benutzer}
                        >
                          {advisor.benutzer}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col><FormControlLabel control={<Switch defaultChecked size="small" onChange={handleSwitchActive}/>} label="Aktive" /></Col>

                <Col >
                  <CustomerSearchDialog />
                </Col>
              </Row>
            </Container>

            <Container className="pt-3" fluid>
              <DataGrid
                className="BAVMainTable"
                getRowId={(row) => row.customerId}
                disableColumnMenu={true}
                loading={loading}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                onRowDoubleClick={handleRowDoubleClick}
                rows={kundendaten}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 15 },
                    },
                    columns: {
                        columnVisibilityModel: {
                            kundenHauptTypCode: false
                        }
                    }
                }}
                pageSizeOptions={[15]}
                rowHeight={40}
                /*slots={{ toolbar: GridToolbar }}*/
              />
            </Container>
          </div>
        </Paper>
      </Container>
    </>
  );
}
