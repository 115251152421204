import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest } from "../authConfig";

export const BAVLogout = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };
  return (
    <div>
      <Button onClick={(event) => handleLogout()} color="inherit">
        Logout
      </Button>
    </div>
  );
};
