import React, { Component, useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useMsal,
} from "@azure/msal-react";
import { BAVLogout } from "./BAVLogout";

function Logout() {
  return (
    <center>
      <BAVLogout />
    </center>
  );
}
export default Logout;
