import React, { Component, useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useMsal,
} from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { BAVLogin } from "./BAVLogin";
import { BAVLogout } from "./BAVLogout";
import { loginRequest } from "../authConfig";
//import { msalInstance } from "../index";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query";
import { debugLog } from "../utils/Util";

function Login() {
  const queryClient = new QueryClient();
  const [userToken, setUserToken] = useState(null);
  const { login, result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );
  useEffect(() => {
    if (!!userToken) {
      // We already have the data, no need to call the API
      debugLog(userToken.idToken);
      return;
    }

    if (!!error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect, request);
      }
      return;
    }

    if (result) {
      getUserToken().then((response) => {
        setUserToken(response);
        debugLog("User token" + response);
      });
    }
  }, [error, result, userToken]);

  const { accounts } = useMsal();

  function Query() {
    const headers = new Headers();
    debugLog(userToken);
    const bearer = `Bearer ${userToken}`;
    debugLog(bearer);
    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };
    const {
      data: customers,
      loading,
      refetch,
    } = useQuery(["customers"], () =>
      fetch(`/customers/search/Meier`, options).then((res) => res.json())
    );
    if (customers) {
      debugLog(customers);
      return <div>CNT:{customers.length}</div>;
    }
  }

  return (
    <>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <Query />
          <Typography variant="h6">
            <center>
              Angemeldet als {accounts.length ? accounts[0].username : ""}{" "}
            </center>
            <center>
              <BAVLogout />
            </center>
          </Typography>
        </QueryClientProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Typography variant="h6">
          <center>
            Sie sind nicht angemeldet oder haben keine Berechtigung für diese
            Applikation
          </center>
          <center>
            {" "}
            Login erneut versuchen <BAVLogin />
          </center>
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
export default Login;
