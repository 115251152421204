import {
  InteractionType,
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";
import { debugLog } from "../utils/Util";

export async function getUserToken() {
  const scopes = [
    "api://602c6f6a-e5d7-40ef-93bb-9515a9af7862/access_as_user",
      "User.Read",
    ];
  debugLog(msalInstance);
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance
      .acquireTokenSilent({
          ...loginRequest,
          account: account,
          scopes: scopes,
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(request);
      }
    });
  debugLog(response);
  debugLog(msalInstance.getActiveAccount().username)
  /* Debug Ablauf des Tokens auf 1 Minute setzen */
  //response.expiresOn = new Date(Date.now() + 60000);
  return response;
}
