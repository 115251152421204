import * as React from "react";
import { Buffer } from "buffer";
import { useState, useEffect } from "react";
import { Box, Container, Alert } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./BAVReport.css";
import { debugLog } from "../utils/Util";

function BAVReport() {
  const [blobURL, setBlobURL] = useState();
  const [error, setError] = useState();
  let [searchParams, setSearchParams] = useSearchParams();
  let entryId = searchParams.get("entryId");
  // Auth Token
  const [options, setOptions] = useState();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();
  const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
  };
  const reInitToken = new Promise((resolve, reject) => {
        if (token && token.expiresOn < Date.now()) {
            debugLog('Start initToken');
            initToken()
                .then((res) => {
                    debugLog('Finished initToken');
                    resolve('initToken');
                })
        } else {
            debugLog('tokenValid');
            resolve('tokenValid');
        }
  });
  const getPdf = async () => {
      reInitToken
          .then((res) => {
              fetch("/report?entryId=" + entryId, {
                  method: "GET",
                  headers: options.headers,
              })
                  .then((res) => {
                      if (res.status == 204) {
                          setError("Keine Daten vorhanden um eine Auswertung durchzuführen");
                          return;
                      }
                      else if (res.status !== 200) {
                          setError("Technischer Fehler, Auswertung konnnte nicht erstellt werden");
                          return;
                      }
                      return res.blob();
                  })
                  .then((blob) => {
                      if (blob) {
                          const file = window.URL.createObjectURL(blob);
                          const iframe = document.querySelector("iframe");
                          if (iframe?.src) iframe.src = file;
                          setLoading(false);
                      }
                  });
          })

  };
  useEffect(() => {
    initToken();
  }, []);
  useEffect(() => {
    if (options) {
      getPdf();
    }
  }, [options]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  } else {
    return (
      <Box height="80vh" display="flex" flexDirection="column">
        <div className="progressCenter">
          <CircularProgress
            color="inherit"
            hidden={loading == false}
            className="progressCenter"
          />
        </div>
        <iframe src="" width="100%" height="100%"></iframe>
      </Box>
    );
  }
}
export default BAVReport;
