import * as React from "react";
import { ToggleButton } from "@mui/material/ToggleButton";
import { useState, useEffect } from "react";
import {
  Grid,
  Slider,
  MuiInput,
  Box,
  Input,
  InputAdornment,
  TextField
} from "@mui/material";
import { NumericFormat, NumericFormatProps } from 'react-number-format';

const NumericFormatCustom = React.forwardRef (
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={"'"}
                valueIsNumericString
                prefix="CHF "
            />
        );
    },
);



function BAVSlider(props) {
  const [value, setValue] = useState(
    initValue(props)
    );
   
  function initValue(props){
      if (props.valueField){
        switch (props.valueField){
            case "value_1":
                 return  props.response.value_1 ? parseInt(props.response.value_1) : 0;
            case "value_2":
                 return  props.response.value_2 ? parseInt(props.response.value_2) : 0;
            case "value_3":
                 return  props.response.value_3 ? parseInt(props.response.value_3) : 0;
        }
      } else {
        return  props.response.value_1 ? parseInt(props.response.value_1) : 0;
      }
  }

  function handleInputChange(e) {
   
    if (e.target.value) {
      setValue(parseInt(e.target.value));
    } else {
      setValue(0);
    }
    
  }
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue);
  };

  function handleBlur(event: Event) {
    if (typeof props.callback == "function") {
      props.callback(props, value);
    }
  }

  function handleChangeCommitted(event: Event, newValue: number) {
    if (typeof props.callback == "function") {
      props.callback(props, newValue);
    }
  }

  useEffect(() => {
    setValue(parseInt(value));
  }, []);

  
if (props.title){
   return (
    <Box sx={{ width: "100%" }} className="bavSlider">
      <Grid container spacing={20} alignItems="center">
        <Grid item xs={2}>
            {props.title}
        </Grid>
        <Grid item xs={7}>
          <Slider
            value={typeof value === "number" ? value : 0}
            onChange={handleSliderChange}
            step={props.step}
            min={props.min}
            max={props.max}
            onChangeCommitted={handleChangeCommitted}
            id={props.id}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={value}
            size="large"
            onChange={handleInputChange}
            onBlur={handleBlur}
            InputProps={{
                inputComponent: NumericFormatCustom
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
} else {
  return (
       <Box sx={{ width: "100%" }} className="bavSlider">
      <Grid container spacing={20} alignItems="center">
        <Grid item xs={8}>
          <Slider
            value={typeof value === "number" ? value : ""}
            onChange={handleSliderChange}
            step={props.step}
            min={props.min}
            max={props.max}
            onChangeCommitted={handleChangeCommitted}
            id={props.id}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={value}
            size="large"
            onChange={handleInputChange}
            onBlur={handleBlur}
            InputProps={{
                inputComponent: NumericFormatCustom
            }}
          />
        </Grid>
      </Grid>
    </Box>
    
  );

  }
}
export default BAVSlider;
