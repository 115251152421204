import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
//import { FocusStyleManager } from "@blueprintjs/core";
import { Container, Row, Col } from "reactstrap";
import { Button, Checkbox, Box } from "@mui/material";
import { useState, useEffect } from "react";
import BAVButtonColumn from "./BAVButtonColumn";
import BAVHistoryPopup from "./BAVHistoryPopup";
import { debugLog } from "../utils/Util";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  deDE,
  GridToolbar,
} from "@mui/x-data-grid";
import "./BAVEntryTable.css";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import { convertUTCDateToLocalDate } from '../utils/Util.jsx';
import { msalInstance } from "../index";

function BAVEntryTable({ data, loading, showPf, hasAdmin, hideCopyButton, showSpType }) {
  //FocusStyleManager.onlyShowFocusOnTabs();
  const navigate = useNavigate();
  const location = useLocation();
  const account = msalInstance.getActiveAccount();
  var _data = data;
  const columns: GridColDef[] = [
   {
      field: "editKtGnr",
      headerName: showPf ? "Portfolio " : "",
      headerClassName: "BAVColumnHeader",
      width: 120,
    },
    {
      field: "changeDate",
      headerName: "Erstell-/Änderungsdatum",
      headerClassName: "BAVColumnHeader",
      width: 180,
      valueGetter: ({ value }) =>
        value && new Date(value).toLocaleString("de-CH"),
    },
    {
      field: "changedBy",
      headerName: "Erstellt/Geändert von",
      headerClassName: "BAVColumnHeader",
      width: 200,
    },
    {
      field: "validFrom",
      headerName: "Gültig von",
      headerClassName: "BAVColumnHeader",
      width: 110,
      valueGetter: ({ value }) =>
        value &&
        new Date(value).toLocaleString("de-CH", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "validTo",
      headerName: "Gültig bis",
      headerClassName: "BAVColumnHeader",
      width: 110,
      valueGetter: ({ value }) =>
        value &&
        new Date(value).toLocaleString("de-CH", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "active",
      headerName: "aktiv",
      headerClassName: "BAVColumnHeader",
      headerAlign: "right",
      width: 450,
      renderCell: (params) => (
        <BAVButtonColumn
          handleAuswertung={() => handleAuswertung(params.row)}
          handleEdit={() => handleEdit(params.row, location.state.kunde)}
          setEntryActive={(e) => setEntryActive(params.row, data, e)}
          active={params.row.active}
          handleCopy={() => handleCopy(params.row)}
          handleDelete={() => handleDelete(params.row)}
          hasAdmin={hasAdmin}
          hideCopyButton={hideCopyButton}
        />
      ),
    },
    {
        field: "HistoryButton",
        headerName: "",
        headerClassName: "BAVColumnHeader",
        headerAlign: "right",
        width: 60,
        align: "right",
        sortable: false,
        renderCell: (params) => (
            <BAVHistoryPopup
                entryId={params.row.entryId}        
        />
        )
    }
  ];
  if(showSpType){
    columns.unshift(
        {
            field: "entryType", 
            width: 20,
            headerName: "",
            sortable: false,
            /*
            renderCell: ({value}) => 
                value==3 &&
                <div> x</div>
                })
                */
            renderCell: params =>{
                if(params.value==3){
                    return(<span>S</span>);
                }
                if(params.value==4){
                    return(<span>V</span>);
                }
            }
            });
  }
  // Auth Token
  const [options, setOptions] = useState();
  const [token, setToken] = useState();
  const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
    };
  const reInitToken = new Promise((resolve, reject) => {
      if (token && token.expiresOn < Date.now()) {
          debugLog('Start initToken');
          initToken()
              .then((res) => {
                  debugLog('Finished initToken');
                  resolve('initToken');
              })
      } else {
          debugLog('tokenValid');
          resolve('tokenValid');
      }
  });
  useEffect(() => {
    initToken();
  }, []);

    function handleRowDoubleClick(row) {
        debugLog(row);
  }
  function handleAuswertung(row) {
    debugLog("handleAuswertung");
    var kd = row;
    //window.open("/BAVReport",  "_blank", "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=600,left=100,top=100");
    window.open("/BAVReport?entryId=" + kd.entryId);
  }
    function handleEdit(row, kunde) {
        var kd = row;
        kd.kdnr = kunde.kdnr;
        kd.editKdNr = kunde.editKdNr;
        kd.kdLnr = kunde.kdLnr;
        kd.surname = kunde.surname;
        kd.name = kunde.name;
        kd.city = kunde.city;
        let requestOptions = {
            method: "POST",
            headers: options.headers,
        };
        // Session Store für zusätzliche Daten neu initialisieren.
        reInitToken
            .then((res) => {
                fetch("/profileform/reInit/", requestOptions)
                    .then((res) => {
                        navigate("/customerProfile", { state: { kunde: kd } });
                    })
            });

  }
  function handleCopy(row) {
    let requestOptions = {
      method: "POST",
      headers: options.headers,
      body: JSON.stringify(row.entryId),
      };
      reInitToken
          .then((res) => {
              fetch("/entries/copy?id=" + row.entryId, requestOptions)
                  .then(function (result) {
                      if (result.status !== 201) {
                          alert("Profilierung konnte nicht kopiert werden");
                          return;
                      } else {
                          alert("Profilierung mit der ID " + row.entryId + " wurde kopiert");
                      }
                      window.location.reload();
                  });
          });
  }
  function handleDelete(row) {
      if (!window.confirm("Möchten Sie die Profilierung mit der ID "+ row.entryId + " wirklich löschen?")){
          return;
      }
      let requestOptions = {
      method: "DELETE",
      headers: options.headers,
      body: JSON.stringify(row.entryId),
      };
      reInitToken
          .then((res) => {
              fetch("/entries/" + row.entryId, requestOptions)
                  .then(function (result) {
                      if (result.status !== 204) {
                          alert("Profilierung konnte nicht gelöscht werden");
                          return;
                      } else {
                          alert("Profilierung mit der ID " + row.entryId + " wurde gelöscht");
                      }
                      window.location.reload();
                     });
          });
   
  }
  function setEntryActive(row, data, e) {
    var kd = row;
    if (e.target.checked) {
      kd.changedBy = account.name;
      kd.changeDate = convertUTCDateToLocalDate(new Date());
      kd.validFrom = convertUTCDateToLocalDate(new Date());
      kd.validTo = new Date("2099-12-31");
      kd.active = 1;
    } else {
      kd.changedBy = account.name;
      kd.changeDate = convertUTCDateToLocalDate(new Date());
      kd.validFrom = kd.createDate;
      kd.validTo = convertUTCDateToLocalDate(new Date());
      kd.active = 0;
    }
    let requestOptions = {
      method: "PUT",
      headers: options.headers,
      body: JSON.stringify(kd),
    };
    reInitToken
          .then((res) => {
              fetch("/entries/" + kd.entryId, requestOptions).then(function () {
                  // Prüfen ob andere Profilierungen bereits aktiv sind
                  if (e.target.checked) {
                      for (let i = 0; i < data.length; i++) {
                          if (data[i].active == 1 && data[i].entryId != kd.entryId && data[i].entryType == kd.entryType && data[i].portfolioId == kd.portfolioId) {
                              data[i].changedBy = account.name;
                              data[i].changeDate = convertUTCDateToLocalDate(new Date());
                              data[i].validFrom = kd.createDate;
                              data[i].validTo = convertUTCDateToLocalDate(new Date());
                              data[i].active = 0;
                              requestOptions = {
                                  method: "PUT",
                                  headers: options.headers,
                                  body: JSON.stringify(data[i]),
                              };
                              fetch("/entries/" + data[i].entryId, requestOptions).then(function () {
                                  debugLog("");
                              });
                          }
                      }
                  }
                  window.location.reload();
              });
          });
  }

  return (
    <>
      <DataGrid
        className="BAVEntryTable"
        getRowId={(row) => row.entryId}
        disableColumnMenu={true}
        loading={loading}
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        onRowDoubleClick={handleRowDoubleClick}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        rowHeight={30}
        columnHeaderHeight={40} // for columnHeader height
      />
    </>
  );
}
export default BAVEntryTable;
