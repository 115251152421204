import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateField } from '@mui/x-date-pickers/DateField';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';

function BAVRadioGroupSparplan(props) {
    const [disabled, setDisabled] = useState();
    const [textValue, setTextValue] = useState(
        props.responses[2] ? props.responses[2].value_1 ? dayjs(props.responses[2].value_1, "DD.MM.YYYY") : null : null
    );
    const [birthdateEnabled, setBirthdateEnabled] = useState();
    function changeText(newValue) {
        if (isValidDate(newValue)) {
            setTextValue(newValue);
            props.setMissingMandatoryValue({ state: false, text: "" });
        } else {
            props.setMissingMandatoryValue({ state: true, text: "Bitte geben Sie ein gültiges Geburtsdatum ein" });
        }
    }

    function changeSelection(e, response) {
        if (e.target.id != "bavDateField") {
            setBirthdateEnabled(e.target.value == "159");
            if (e.target.value != "159") {
                props.setMissingMandatoryValue({ state: false, text: "" });
            } else {
                if (!isValidDate(textValue)) {
                    props.setMissingMandatoryValue({ state: true, text: "Bitte geben Sie ein gültiges Geburtsdatum ein" });
                }
            }
        }
        setDisabled(true);
        if (typeof props.callback == "function") {
            props.callback(props, e.target.value, response, e.target);
        }
        setDisabled(false);
    }

    useEffect(() => {

        if (props.responses[0] && props.responses[0].value_1 == props.responses[0].responseId) {
            setBirthdateEnabled(true);
            if (!isValidDate(textValue)) {
                props.setMissingMandatoryValue({ state: true, text: "Bitte geben Sie ein gültiges Geburtsdatum ein" });
            }
            
        } else {
            setBirthdateEnabled(false);
            props.setMissingMandatoryValue({state: false, text:"" });
        }

 
        }, []);

    function isValidDate(date) {
        return dayjs(date, 'DD.MM.YYYY').isValid() && !isNaN(date);
    }
    function renderRadio() {
        const objs: JSX.Element[] = [];
        if (props.responses.length > 0) {
            for (let i = 0; i < props.responses.length; i++) {
            if (i < 2) {
              objs.push(
                <FormControlLabel
                  value={props.responses[i].responseId}
                  key={
                    props.responses[i].questionId +
                    "_" +
                    props.responses[i].responseId +
                    "_" +
                    props.responses[i].entryDetailId
                  }
                  control={
                    <Radio
                      id={"bavRadioCond" + props.responses[i].responseId + "_" + i}
                      sx={{
                        "&.Mui-checked": {
                          color: "#006e58",
                        },
                      }}
                      onChange={(e) => changeSelection(e, props.responses[i])}
                      checked={
                        props.responses[i].responseId == props.responses[i].value_1
                      }
                      disabled={disabled}
                    />
                  }
                  label={props.responses[i].response}
                />
              );
            }
          }
        }
        return objs;
    }

    function renderDatefield() {
         var showBirthday = 'block';
         if (!birthdateEnabled) {
             showBirthday = 'none';
         }
        if (props.responses.length > 0) {
            for (let i = 0; i < props.responses.length; i++) {
                if (i == 2) {
                    return (
                        <>
                            <Box sx={{display: showBirthday} }>
                                <Typography component={'div'} variant="body1" ml={1} mt={1}>
                                    <Box>{props.responses[i].response}</Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateField
                                            value={textValue}
                                            format="DD.MM.YYYY"
                                            required={false}
                                            onChange={(newValue) => changeText(newValue)}
                                            onBlur={(e) => changeSelection(e, props.responses[i])}
                                            id={"bavDateField"}
                                        />
                                    </LocalizationProvider>
                                </Typography>
                            </Box>
                        </>
                    );
                }
            }
        }
     }

    return (
        <>
        <FormControl>
        <RadioGroup
          aria-labelledby="bav-radio-buttons-groupCond-label"
          defaultValue=""
          name="bav-radio-buttons-groupCond"
          className="bavRadioGroupCond"
          style={{ paddingTop: "10px", paddingLeft: "12px" }}
        >
          {renderRadio()}
        </RadioGroup>
        </FormControl>
        {renderDatefield()}
        </>
    );
}
export default BAVRadioGroupSparplan;
