import * as React from "react";
import BAVButton from "./BAVButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function BAVMenuButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <>
        <BAVButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className="m-3"
            variant="contained"
            style={{
                maxWidth: "120px",
                maxHeight: "25px",
                minWidth: "30px",
                minHeight: "25px",
             }}>Neu
         </BAVButton>
         <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
         >
            <MenuItem sx={{fontSize: 13}} onClick={function(event){handleClose(); props.opener(3);}}>Sparplan</MenuItem>
            <MenuItem sx={{fontSize: 13}} onClick={function(event){handleClose(); props.opener(4);}}>Vorsorge</MenuItem>
         </Menu>
         </>
    )
}
export default BAVMenuButton;