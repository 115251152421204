import * as React from "react";
import { Button} from "@mui/material";
import { styled } from "@mui/system";

const BAVButton = styled(Button)(({ variant }) => ({
      ...(variant === "contained" && {
      color: 'white',
      backgroundColor: '#f2b702',
      borderColor: '#f2b702',
      '&:hover': {
        backgroundColor: '#f59c0a',
        borderColor: '#f59c0a'
      }
      }),
      ...(variant === "outlined" && {
      color: '#f59c0a',
      backgroundColor: 'white',
      borderColor: '#f2b702',
      '&:hover': {
        color: '#f59c0a',
        fontWeight: 'bold',
        backgroundColor: 'white',
        borderColor: '#f59c0a'
      }
      }),
      ...(variant === "closing" && {
      color: 'white',
      backgroundColor: '#2e7d32',
      borderColor: '#2e7d32',
      border: '1px solid',
      '&:hover': {
        color: 'white',
        backgroundColor: '#006e58'
      },
      '&:disabled': {
          backgroundColor: 'white',
          borderColor: 'lightgrey'
      }
      })
  }))
  export default BAVButton;