import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest } from "../authConfig";

export const BAVLogin = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };
  return (
    <div>
          <Button onClick={(event) => handleLogin()} variant="contained">
        Login
      </Button>
    </div>
  );
};
