import React, { Component } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import "./custom.css";
import { BAVLogin } from "./components/BAVLogin";
// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useMsal,
} from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { createContext, useState, useEffect } from "react";

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <>
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
          <Layout>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
            </Routes>
          </Layout>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <center>
            Sie sind nicht angemeldet oder haben keine Berechtigung für diese
            Applikation
          </center>
          <center>
            {" "}
            Login erneut versuchen <BAVLogin />
          </center>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </>
  );
}
export default App;
