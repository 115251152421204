import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Alert
} from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import BAVToggleButton from "./BAVToggleButton";
import BAVRadioGroup from "./BAVRadioGroup";
import BAVRadioGroupESG from "./BAVRadioGroupESG";
import BAVRadioGroupSparplan from "./BAVRadioGroupSparplan";
import BAVSlider from "./BAVSlider";
import BAVRisk from "./BAVRisk";
import BAVTextArea from "./BAVTextArea";
import PdfIcon from "@mui/icons-material/PictureAsPdfOutlined";
import "./CustomerProfile.css";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import BAVButton from "./BAVButton";
import {convertUTCDateToLocalDate} from '../utils/Util.jsx';
import { msalInstance } from "../index";
import { debugLog } from "../utils/Util";


const queryClient = new QueryClient();

const CustomerProfile = () => {
  const location = useLocation();
  return (
    <QueryClientProvider client={queryClient}>
      <BAVStepper />
    </QueryClientProvider>
  );
};
export default CustomerProfile;

function BAVStepper() {
  const navigate = useNavigate();
  const location = useLocation();
  const account = msalInstance.getActiveAccount();
  if (!location.state) {
    // Testmodus ohne Übergabeparameter
    var entrytype = 2;
    var entryid = "6";
  } else {
    var kd = location.state.kunde;
    var entrytype = kd.entryType;
    var entryid = kd.entryId;
  }
  // Auth Token
  const [options, setOptions] = useState();
  const [token, setToken] = useState();
  const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
  };
  const reInitToken = new Promise((resolve, reject) => {
        if (token && token.expiresOn < Date.now()) {
            debugLog('Start initToken');
            initToken()
                .then((res) => {
                    debugLog('Finished initToken');
                    resolve('initToken');
                })
        } else {
            debugLog('tokenValid');
            resolve('tokenValid');
        }
  });

  //debugLog ('Uebergabe: entrytype:'+entrytype+', entryid='+entryid);
  const { data: allEntries, refetch } = useQuery(
    "ProfileFormData",
    fetchProfileFormData,
    { enabled: !!options }
    );

  const [entries, setEntries] = useState();
  const [questions, setQuestions] = useState();
  const [responses, setResponses] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [isValid, setIsValid] = useState(true);
  // MissingMandatoryValue soll von Komponente gesetzt werden. Weiter wird dann unterdrückt und Fehlermeldung eingeblendet
  const [missingMandatoryValue, setMissingMandatoryValue] = useState({ state: false, text: "" });

  useEffect(() => {
    if (allEntries) {
      //debugLog('setAllEntries');
      setEntries(allEntries);
      var questions = getQuestions(allEntries);
      setQuestions(questions);
      var responses = getResponses(allEntries, questions);
      setResponses(responses);
    }
  }, [allEntries, activeStep]);

  useEffect(() => {
    initToken();
  }, []);

  
  /**
   * **************************************************************
   *
   * VARIABLEN
   *
   * **************************************************************
   */

  // Diese Variablen müssen von Parent übergeben werden

  // Beispiel Kundenprofil

  //var entrytype = 1;
  //var entryid = "5";

  // Beispiel Portfolioprofil
  //var entrytype = 2;
  //var entryid = "6";

  let arrInit = [];

  /**
   * **************************************************************
   *
   * KONSTANTEN
   *
   * **************************************************************
   */

  const defaultColStyle = {
    maxWidth: "100px",
    wordWrap: "break-word",
  };

  /**
   *
   * Handlingsfunktionen des Steppers
   *
   */
    const handleNext = () => {
      if (missingMandatoryValue.state) {
          return;
      }
      setMissingMandatoryValue({state: false, text:""})
      setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
      if (missingMandatoryValue.state) {
          return;
      }
      setMissingMandatoryValue({ state: false, text: "" })
      setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
      setMissingMandatoryValue({ state: false, text: "" })
      setActiveStep(0);
  };
    const handleBackToCustomer = () => {
      navigate(-1);
  };

  const handleAuswertung = () => {
      window.open("/BAVReport?entryId=" + entryid);
    }



  /**
   * Die ResponseTypes
   *
   *    1 = Auswahlliste
   *    2 = Multiselect
   *    3 = Slider kleine Schritte
   *    4 = Slider grössere Schritte
   *    5 = Auswahlliste mit Vorschlag/Berechnung
   *    6 = Risikoprofil
   *    7 = Bemerkungen
   *    8 = Auswahlliste spezial ESG
   *    *
   */

  const responseType1Component = () => {
    return (
      <>
        <Container fluid>
          <BAVRadioGroup
            responses={responses}
            callback={handleRadioGroupChange}
            key={responses[0].questionId}
          />
        </Container>
      </>
    );
  };

  const responseType2Component = (pEntryType) => {
    /*
      responses.map((_response, _index) => (
        debugLog(_response)
    
    ));
    */
    return (
      <TableContainer>
        <Table aria-label="responseTable">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "250px" }}>BEZEICHNUNG</TableCell>
              <TableCell align="left" style={defaultColStyle}>
                KENNTNISSE VORHANDEN
              </TableCell>
              <TableCell align="left" style={defaultColStyle}>
                ERFAHRUNG VORHANDEN
              </TableCell>
              <TableCell align="left" style={defaultColStyle}>
                AUFKLÄRUNG ERHALTEN
              </TableCell>
              <TableCell align="left" style={defaultColStyle}>
                PRODUKT-INFORMATIONSBLATT
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responses.map((_response, _index) => (
              <TableRow key={_response.responseId}>
                <TableCell
                  key={_response.responseId + "A"}
                  component="th"
                  scope="row"
                  style={{ minWidth: "250px" }}
                >
                  {_response.response}
                </TableCell>
                <TableCell
                  key={_response.responseId + "B"}
                  align="left"
                  style={defaultColStyle}
                >
                  <BAVToggleButton
                            buttonId={"btnr_1_" + _response.responseId}
                            questionid={_response.questionId}
                            responseid={_response.responseId}
                            button="1"
                            value_1={getButtonValue(1, _response.responseId)}
                            value_2={getButtonValue(2, _response.responseId)}
                            value_3={getButtonValue(3, _response.responseId)}
                            callback={handleButtonChange}
                            init={initButton}
                            entryType={pEntryType}
                  />
                </TableCell>
                <TableCell
                  key={_response.responseId + "C"}
                  align="left"
                  style={defaultColStyle}
                >
                  <BAVToggleButton
                    buttonId={"btnr_2_" + _response.responseId}
                    questionid={_response.questionId}
                    responseid={_response.responseId}
                    button="2"
                    value_1={getButtonValue(1, _response.responseId)}
                    value_2={getButtonValue(2, _response.responseId)}
                    value_3={getButtonValue(3, _response.responseId)}
                    callback={handleButtonChange}
                    init={initButton}
                  />
                </TableCell>
                <TableCell
                  key={_response.responseId + "D"}
                  align="left"
                  style={defaultColStyle}
                >
                  <BAVToggleButton
                    buttonId={"btnr_3_" + _response.responseId}
                    questionid={_response.questionId}
                    responseid={_response.responseId}
                    button="3"
                    value_1={getButtonValue(1, _response.responseId)}
                    value_2={getButtonValue(2, _response.responseId)}
                    value_3={getButtonValue(3, _response.responseId)}
                    callback={handleButtonChange}
                    init={initButton}
                  />
                </TableCell>
                <TableCell
                  key={_response.responseId + "E"}
                  align="left"
                  style={defaultColStyle}
                >
                  <PdfIcon
                    onClick={(event) => {
                        window.open(process.env.REACT_APP_ABP_DOCUMENT_URL + _response.responseValue,
                        "_blank",
                        "popup=true,resizable=yes,top=500,width=800,height=800,left=100"
                      );
                      }
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const responseType3Component = () => {
    return (
      <BAVSlider
        min={0}
        max={responses[0].responseValue!=''?parseInt(responses[0].responseValue):500000}
        step={5000}
        response={responses[0]}
        key={responses[0].responseId}
        callback={handleSliderChange}
      />
    );
  };

  const responseType4Component = () => {
    return (
      <BAVSlider
        min={0}
        max={responses[0].responseValue!=''?responses[0].responseValue:500000}
        step={5000}
        response={responses[0]}
        key={responses[0].responseId}
        callback={handleSliderChange}
      />
    );
  };
  const responseType5Component = () => {
    return (
      <>
        <Container fluid>
          <BAVRadioGroup
            responses={responses}
            callback={handleRadioGroupChange}
            invRec={responses[0].questionId == 14 ? 1 : 0}
            invStrat={responses[0].questionId == 20 || responses[0].questionId == 31 ||  responses[0].questionId == 39? 1 : 0}
            invClientRisk={responses[0].questionId == 9 ? 1: 0}
            key={responses[0].questionId}
          />
        </Container>
      </>
    );
  };
  const responseType6Component = () => {
    return <BAVRisk response={responses[0]} callback={handleRiskChange} />;
  };
  const responseType7Component = () => {
    const handleValidation = (isValid) => {
        setIsValid(isValid);
    }
      
      return (
      <>
      <Container fluid> 
        <Typography mt={1} variant="subtitle2" sx={{fontWeight:'light'}}>{responses[0].value_2}</Typography >
        <Typography mt={1} variant="subtitle2" sx={{fontWeight:'light'}}>{responses[0].value_3} </Typography >
        <BAVTextArea
            responses={responses}
            callback={handleTextAreaChange}
            key={responses[0].questionId}
            required={responses[0].value_2 || responses[0].value_3?true:false}
            validation={handleValidation}
          />
        </Container>
      </>
    );
  };
  const responseType8Component = () => {
    return (
      <>
        <Container fluid>
          <BAVRadioGroupESG
            responses={responses}
            callback={handleRadioGroupESGChange}
            key={responses[0].questionId}
            invRec={1}
          />
        </Container>
      </>
    );
  };

  const responseType9Component = () => {
    var settings = ["1", "1"];
    if (responses[0].responseValue!=''){
        settings = responses[0].responseValue.split(";");
        if(settings.length==1){
            settings[1] = "2000";
        }
    }
    return (
      <>
      <BAVSlider
        min={0}
        max={parseInt(settings[0])}
        step={1000}
        response={responses[0]}
        key={responses[0].responseId}
        callback={handleChangeSparplan}
        title={"Einmalig"}
        valueField={"value_1"}
       />
       <BAVSlider
        min={0}
        max={parseInt(settings[1])}
        step={50}
        response={responses[0]}
        key={responses[0].responseId+"1"}
        callback={handleChangeSparplan}
        title={"Wiederkehrend"}
        valueField={"value_2"}
       />
           <Box sx={{ width: "100%" }} className="bavSlider">
              <Grid container spacing={20} alignItems="center">
                  <Grid item xs={2}>
                    Periodizität
                  </Grid>
                  <Grid item xs={8}>
                   <FormControl>
                   <RadioGroup
                        row
                        aria-labelledby="bav-row-radio-buttons-group-label"
                        name="bav-row-radio-buttons-group"
                        value={responses[0].value_3}
                        onChange={(e)=>handlePeriodizityChange(e, responses[0])}
                   >
                       <FormControlLabel value="1" control={<Radio />} label="monatlich" />
                       <FormControlLabel value="2" control={<Radio />} label="zweimonatlich" />
                       <FormControlLabel value="3" control={<Radio />} label="quartalsweise" />
                       <FormControlLabel value="4" control={<Radio />} label="halbjährlich" />
                       <FormControlLabel value="5" control={<Radio />} label="jährlich" />
                   </RadioGroup>
                   </FormControl>
                </Grid>
              </Grid>
            </Box>
      </>
    );
    };

    const responseType10Component = () => {
        return (
            <>
                <Container fluid>
                    <BAVRadioGroupSparplan
                        responses={responses}
                        callback={handleRadioGroupSparplanChange}
                        key={responses[0].questionId}
                        invRec={1}
                        setMissingMandatoryValue={setMissingMandatoryValue}
                    />
                </Container>
            </>
        );
    }

  // EntryType für für die Fragen Typ 2 verwendet, da diese auf Kunden und Portfolioebene angezeigt werden können
  const renderResponses = (pEntryType) => {
    // In der Konfiguration kann pro Seite nur der gleiche ResponseType verwendet werden.
    if (responses.length == 0) {
      return;
    }
    if (responses[0].responseType!=7){
        if(!isValid){
            setIsValid(true);
        }
    }
    switch (responses[0].responseType) {
      case 1:
        return responseType1Component();
        break;
      case 2:
        return responseType2Component(pEntryType);
        break;
      case 3:
        return responseType3Component();
        break;
      case 4:
        return responseType4Component();
        break;
      case 5:
        return responseType5Component();
        break;
      case 6:
        return responseType6Component();
        break;
      case 7:
        return responseType7Component();
        break;
      case 8:
        return responseType8Component();
        break;
      case 9:
        return responseType9Component();
        break;
      case 10:
        return responseType10Component();
        break;
    }
  };

  const renderCurrentStep = () => {

    const currentQuestion = questions[activeStep];
    //debugLog('renderCurrentStep');
    if (!currentQuestion) {
      return "Keine Konfigurationen für Entry Typ: " + entrytype;
    }
    return (
      <>
        <main className="main">
          <Box className="area">
            <div>
              {currentQuestion.entryType == 1
                ? "KUNDENEBENE"
                : currentQuestion.entryType == 2
                ? "PORTFOLIO"
                : currentQuestion.entryType == 3
                ? "SPARPLAN"
                : currentQuestion.entryType == 4
                ? "VORSORGE"
                : ""}
            </div>
            <hr />
          </Box>
          <Box className="question">
            <div>
              <b>{currentQuestion.question}</b>
            </div>
          </Box>
                <Box className="response">{renderResponses(currentQuestion.entryType)}</Box>
                {missingMandatoryValue.state &&(<><p/>< Alert severity="error" >{missingMandatoryValue.text}</Alert></>)}
        </main>
        <footer className="footer">
          <Box sx={{ flex: "1 1 auto" }} />
          <div className="buttonContainer">
           <BAVButton variant="outlined" onClick={handleBackToCustomer}>
              Zurück zum Kunden
            </BAVButton>
            <BAVButton
              variant="outlined"
              onClick={handleAuswertung}
            > Auswertung
            </BAVButton>
            <BAVButton
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0 || missingMandatoryValue.state}
            >
              Zurück
            </BAVButton>
            <BAVButton 
              variant="contained"
                onClick={handleNext}
                disabled={activeStep === totalSteps - 1 || missingMandatoryValue.state}
            >
              Weiter
            </BAVButton>
            <Box mr={18.3} hidden={activeStep == totalSteps - 1} />
            <BAVButton
                    variant="closing"
                    hidden={activeStep !== totalSteps - 1}
                    onClick={handleSetProfileActive}
                    disabled={!isValid}
                >Abschliessen
            </BAVButton>
            

          </div>
        </footer>
      </>
    );
  };

  /**
   * *************************************************************
   *
   * FUNKTIONEN
   *
   * *************************************************************
   */

  function fetchProfileFormData() {
    try {
        return reInitToken
            .then((res) => {
                return fetch("/profileform?entryid=" + entryid, options)
                    .then((res) => {
                        return res.json();
                    }
                    );
            });
    } catch (e) {
      debugLog(e);
      initToken();
      }
  }

  function saveChange(postData) {
    if (postData.entryDetailId > 0) {
      //debugLog(postData);
      let requestOptions = {
        method: "PUT",
        headers: options.headers,
        body: JSON.stringify(postData),
      };
        reInitToken
            .then((res) => {
                fetch("/entrydetails/" + postData.entryDetailId, requestOptions)
                    .then(() => {
                        refetch();
                    })
                    .catch((error) => {
                        refetch();
                    });
            });
        
    } else {
      //debugLog(postData);
      let requestOptions = {
        method: "POST",
        headers: options.headers,
        body: JSON.stringify(postData),
      };
        reInitToken
            .then((res) => {
                fetch("/entrydetails", requestOptions)
                    .then(() => {
                        refetch();
                    })
                    .catch((error) => {
                        refetch();
                    });
            });
       
    }
  }


  function handleRiskChange(props, newValue) {
    var index = entries.findIndex(
      (d) => d.responseId == props.response.responseId
    );
    var entry = entries[index];
    if (!entry || !entry.entryDetailId) {
      entry = {
        entryDetailId: "0",
        entryId: props.response.entryId,
        entryDetailType: entrytype,
        questionId: props.response.questionId,
        responseId: props.response.responseId,
        value_1: newValue + "",
        createDate: convertUTCDateToLocalDate(new Date()),
        createdBy: account.name,
        changeDate: convertUTCDateToLocalDate(new Date()),
        changedBy: account.name,
      };
    } else {
      entry["changeDate"] = convertUTCDateToLocalDate(new Date());
      entry["changedBy"] = account.name;
      entry["value_1"] = newValue + "";
    }
    var lEntries = [...entries];
    lEntries[index] = entry;
    setEntries(lEntries);
    saveChange(entry);
  }
  
    const handleStep = (step: number) => () => {
      if (missingMandatoryValue.state) {
          return;
      }
      setMissingMandatoryValue({ state: false, text: "" })
      setActiveStep(step);
  };
  function handleSliderChange(props, newValue) {
    //debugLog('handleSliderChange');
    //debugLog(newValue);
    //debugLog(props.response);
    var index = entries.findIndex(
      (d) => d.responseId == props.response.responseId
    );
    var entry = entries[index];
    if (!entry || !entry.entryDetailId) {
      entry = {
        entryDetailId: "0",
        entryId: props.response.entryId,
        entryDetailType: entrytype,
        questionId: props.response.questionId,
        responseId: props.response.responseId,
        value_1: newValue + "",
        createDate: convertUTCDateToLocalDate(new Date()),
        createdBy: account.name,
        changeDate: convertUTCDateToLocalDate(new Date()),
        changedBy: account.name,
      };
    } else {
      entry["changeDate"] = convertUTCDateToLocalDate(new Date());
      entry["changedBy"] = account.name;
      entry["value_1"] = newValue + "";
    }
    var lEntries = [...entries];
    lEntries[index] = entry;
    setEntries(lEntries);
    saveChange(entry, lEntries);
  }

  function handleChangeSparplan(props, newValue){
    var valueField = props.valueField?props.valueField:"value_1";
    var index = entries.findIndex(
      (d) => d.responseId == props.response.responseId
    );
    var entry = entries[index];
    if (!entry || !entry.entryDetailId) {
      entry = {
        entryDetailId: "0",
        entryId: props.response.entryId,
        entryDetailType: entrytype,
        questionId: props.response.questionId,
        responseId: props.response.responseId,
        createDate: convertUTCDateToLocalDate(new Date()),
        createdBy: account.name,
        changeDate: convertUTCDateToLocalDate(new Date()),
        changedBy: account.name,
      };
        entry["value_1"] = "0"; // hier initialisieren, weil Feld Pflicht ist
        entry[valueField] = newValue + "";
    } else {
      entry["changeDate"] = convertUTCDateToLocalDate(new Date());
      entry["changedBy"] = account.name;
      entry[valueField] = newValue + "";
    }
    var lEntries = [...entries];
    lEntries[index] = entry;
    setEntries(lEntries);
    saveChange(entry, lEntries);
  }
  function handleTextAreaChange(props, textValue, response) {
    var index = entries.findIndex(
      (d) =>
        d.questionId == response.questionId &&
        d.responseId == response.responseId &&
        d.entryDetailId
    );
    var entry = entries[index];
    //debugLog(entry);
    if (!entry || !entry.entryDetailId) {
      entry = {
        entryDetailId: "0",
        entryId: response.entryId,
        entryDetailType: entrytype,
        questionId: response.questionId,
        responseId: response.responseId,
        value_1: textValue,
        createDate: convertUTCDateToLocalDate(new Date()),
        createdBy: account.name,
        changeDate: convertUTCDateToLocalDate(new Date()),
        changedBy: account.name,
      };
    } else {
      entry["changeDate"] = convertUTCDateToLocalDate(new Date());
      entry["changedBy"] = account.name;
      entry["responseId"] = response.responseId;
      entry["value_1"] = textValue;
    }
    var lEntries = [...entries];
    lEntries[index] = entry;
    setEntries(lEntries);
    saveChange(entry);
  }

  function handleRadioGroupESGChange(props, value, response, component) {
    if (component.tagName == "TEXTAREA") {
        handleTextAreaChange(props, value, response);
    } else {
      handleRadioGroupChange(props, value, response);
    }
    }

    function isValidDate(dateString) {
        // Prüfung ob Datum im Format DD.MM.YYYY gültig ist
        let parts = dateString.split(".");
        if (parts.length !== 3) {
            return false;
        }
        let day = parseInt(parts[0], 10);
        let month = parseInt(parts[1], 10) - 1; // Monate sind von 0 bis 11
        let year = parseInt(parts[2], 10);
        let date = new Date(year, month, day);
        if (date.getDate() === day && date.getMonth() === month && date.getFullYear() === year) {
            return true;
        } else {
            return false;
        }
    }
  function handleRadioGroupSparplanChange(props, value, response, component) {
        if (component.id == "bavDateField") {
            
            if (!isValidDate(value)) {
                value = '';
            }
            handleTextAreaChange(props, value, response);
        } else {
            handleRadioGroupChange(props, value, response);
        }
    }

  function handleRadioGroupChange(props, radioGroupValue, response) {

    var index = entries.findIndex(
      (d) => d.questionId == response.questionId && d.entryDetailId
    );
    var entry = entries[index];
    //debugLog(entry);
    if (!entry) {
      entry = {
        entryDetailId: "0",
        entryId: response.entryId,
        entryDetailType: entrytype,
        questionId: response.questionId,
        responseId: radioGroupValue,
        value_1: radioGroupValue,
        createDate: convertUTCDateToLocalDate(new Date()),
        createdBy: account.name,
        changeDate: convertUTCDateToLocalDate(new Date()),
        changedBy: account.name,
      };
    } else {
      if(!entry.entryDetailId){
          debugLog('Keine DetailId vorhanden, update wird nicht durchgeführt');
          return;
      }
      entry["changeDate"] = convertUTCDateToLocalDate(new Date());
      entry["changedBy"] = account.name;
      entry["responseId"] = radioGroupValue;
      entry["value_1"] = radioGroupValue;
    }
    saveChange(entry);
    var lEntries = [...entries];
    lEntries[index] = entry;
    setEntries(lEntries);
  }
  const handlePeriodizityChange = (e, response) => {
      var index = entries.findIndex(
          (d) => d.questionId == response.questionId && d.entryDetailId
      );
      var entry = entries[index];
      if (!entry) {
          entry = {
              entryDetailId: "0",
              entryId: response.entryId,
              entryDetailType: entrytype,
              questionId: response.questionId,
              responseId: response.responseId,
              value_1: "0",
              value_3: e.target.value,
              createDate: convertUTCDateToLocalDate(new Date()),
              createdBy: account.name,
              changeDate: convertUTCDateToLocalDate(new Date()),
              changedBy: account.name,
          };
      } else {
          if (!entry.entryDetailId) {
              debugLog('Keine DetailId vorhanden, update wird nicht durchgeführt');
              return;
          }
          entry["changeDate"] = convertUTCDateToLocalDate(new Date());
          entry["changedBy"] = account.name;
          entry["responseId"] = response.responseId;
          entry["value_3"] = e.target.value;
      }
      saveChange(entry);
      var lEntries = [...entries];
      lEntries[index] = entry;
      setEntries(lEntries);

  }

  async function handleButtonChange(props, buttonState) {

    var index = entries.findIndex((d) => d.responseId == props.responseid);
    var entry = entries[index];
    if (!entry.entryDetailId) {
      entry = {
        id: props.buttonId,
        entryDetailId: "0",
        entryId: entry.entryId,
        entryDetailType: props.entryType,
        questionId: props.questionid,
        responseId: props.responseid,
        createDate: convertUTCDateToLocalDate(new Date()),
        createdBy: account.name,
        changeDate: convertUTCDateToLocalDate(new Date()),
        changedBy: account.name,
      };
    } else {
      entry["changeDate"] = convertUTCDateToLocalDate(new Date());
      entry["changedBy"] = account.name;
    }
    switch (props.button) {
      case "1":
        entry.value_1 = String(buttonState);
        entry.value_2 = entry.value_2 ? String(entry.value_2) : "0";
        entry.value_3 = entry.value_3 ? String(entry.value_3) : "0";
        break;
      case "2":
        entry.value_1 = entry.value_1 ? String(entry.value_1) : "0";
        entry.value_2 = String(buttonState);
        entry.value_3 = entry.value_3 ? String(entry.value_3) : "0";
        break;
      case "3":
        entry.value_1 = entry.value_1 ? String(entry.value_1) : "0";
        entry.value_2 = entry.value_2 ? String(entry.value_2) : "0";
        entry.value_3 = String(buttonState);
        break;
    }

    var lEntries = [...entries];
    lEntries[index] = entry;
    setEntries(lEntries);
    saveChange(entry);
  }

  function handleSetProfileActive(){
      kd.changedBy = account.name;
      kd.changeDate = convertUTCDateToLocalDate(new Date());
      kd.validFrom = convertUTCDateToLocalDate(new Date());
      kd.validTo = new Date("2099-12-31");
      kd.active = 1;
      let requestOptions = {
        method: "PUT",
        headers: options.headers,
        body: JSON.stringify(kd),
      };
      reInitToken
          .then((res) => {
              fetch("/entries/" + entryid, requestOptions)
                  .then(function () {
                      // Prüfen ob eine andere Profilierung aktiv ist
                      requestOptions = {
                          method: "GET",
                          headers: options.headers,
                      }
                      const checkActive = async () => {
                          if (token.expiresOn < Date.now()) {
                              reInitToken();
                          }
                          const response = await fetch(
                              "/entries/" + kd.customerId,
                              requestOptions
                          );
                          const data = await response.json();
                          for (let i = 0; i < data.length; i++) {
                              if (data[i].active == 1 && data[i].entryId != kd.entryId && data[i].entryType == kd.entryType && data[i].portfolioId == kd.portfolioId) {
                                  data[i].changedBy = account.name;
                                  data[i].changeDate = convertUTCDateToLocalDate(new Date());
                                  data[i].validFrom = kd.createDate;
                                  data[i].validTo = convertUTCDateToLocalDate(new Date());
                                  data[i].active = 0;
                                  requestOptions = {
                                      method: "PUT",
                                      headers: options.headers,
                                      body: JSON.stringify(data[i]),
                                  };
                                  if (token.expiresOn < Date.now()) {
                                      reInitToken();
                                  }
                                  fetch("/entries/" + data[i].entryId, requestOptions).then(function () {
                                      debugLog("");
                                  });
                              }
                          }
                          navigate("/customerDetail", { state: { kunde: location.state.kunde } })
                          navigate(0);
                      }
                      checkActive();

                  });
          });

  }

  function getButtonValue(btnIndex, responseid) {
    var ret = 0;
    entries.forEach(function (entry, i) {
      if (entry.responseId == responseid) {
        ret = entry["value_" + btnIndex];
      }
    });
    return ret;
  }

  function initButton() {}

  function getQuestions(lentries) {
    var q = [];
    lentries.forEach(function (item) {
      var i = q.findIndex((x) => x.questionId == item.questionId);
      if (i <= -1) {
        q.push({
          questionId: item.questionId,
          entryType: item.entryType,
          question: item.question,
        });
      }
    });
    return q;
  }

  function getResponses(lentries, lquestions) {
    var actQuestion = lquestions[activeStep];
    var r = [];
      try {
          lentries.forEach(function (item) {
              if (actQuestion.questionId == item.questionId) {
                  r.push(item);
              }
          });
      } catch (e) {
          handleReset();
      }
    //debugLog(r);
    return r;
  }


  /**
   * **************************************************************
   *
   * KOMPONENTENIMPLEMENTATION
   *
   * **************************************************************
   *
   */

  if (!entries) {
    return <div>Loading...</div>;
  }

  var totalSteps = questions.length;

  return (
    <Container className="mainContainer">
          <Paper>
          <div className="pageContainer">
          <header className="header">
            <Stepper nonLinear activeStep={activeStep} className="bavStepper">
              {questions.map((question, index) => (
                <Step key={question.questionId}>
                      <StepButton onClick={handleStep(index)}>
                </StepButton>
                </Step>
              ))}
            </Stepper>
          </header>
          {renderCurrentStep()}
        </div>
      </Paper>
    </Container>
  );
}
