import * as React from "react";
import {
  Column,
  Table2,
  Cell,
  ColumnHeaderCell2,
  SelectionModes,
} from "@blueprintjs/table";
import { Button, Checkbox, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState, useEffect } from "react";
import FactCheckIcon from '@mui/icons-material/FactCheck';

function BAVButtonColumn(props) {
  const { handleAuswertung, handleEdit, setEntryActive, active, handleCopy, handleDelete, hasAdmin, hideCopyButton } =
    props;
  const [disabled, setDisabled] = useState(active == 1 ? true : false);
  const [_active, setActive] = useState(active == 1 ? true : false);
  const [ showDlg, setShowDlg ] = useState(false);

  function handleActive(e) {
    setDisabled((oldvalue) => !oldvalue);
    var newval = !_active;
    setActive(newval);
    setEntryActive(e);
  }
  function handleReconfirm(e) {
      const levnt = {
          target: {
              checked: true
          }
      }
      setActive(true);
      setEntryActive(levnt);
      setShowDlg(false);
  }
    function handleCloseDlg() {
        setShowDlg(false);
    }

  return (
    <>
      <Button
        className="m-1 bav"
        onClick={handleAuswertung}
        variant="contained"
        style={{
          maxWidth: "120px",
          maxHeight: "20px",
          minWidth: "30px",
          minHeight: "20px",
        }}
      >
        Auswertung
      </Button>
      <Button
        className="m-1 bav"
        onClick={handleEdit}
        variant="contained"
        style={{
          maxWidth: "120px",
          maxHeight: "20px",
          minWidth: "30px",
          minHeight: "20px",
        }}
        disabled={disabled}
      >
        Bearbeiten
      </Button>
      <Button
        className="m-1 bav"
        onClick={handleCopy}
        variant="contained"
        style={{
          maxWidth: "120px",
          maxHeight: "20px",
          minWidth: "30px",
          minHeight: "20px",
        }}
        hidden={hideCopyButton}
      >
        Kopieren
      </Button>
     {hasAdmin ? (
      <Button
        className="m-1 bav"
        onClick={handleDelete}
        variant="contained"
        style={{
          maxWidth: "120px",
          maxHeight: "20px",
          minWidth: "30px",
          minHeight: "20px",
        }}
        disabled={disabled}
      >
        Löschen
      </Button>) : ""}
      <Checkbox onChange={handleActive} checked={_active}></Checkbox>
      {process.env.REACT_APP_RECONFIRMBUTTON == 'true' && _active ? (
          <>
          <Tooltip title="Profil für 3 Jahre bestätigen">
              <IconButton
                  color="success"
                  onClick={()=>setShowDlg(true)}
              >
                <FactCheckIcon />
              </IconButton>
       </Tooltip>
          <Dialog
              open={showDlg}
              onClose={handleCloseDlg}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">
                  {"Anlageprofil bestätigen"}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Hiermit bestätigen Sie, dass das ausgewählte Anlageprofil weiterhin unverändert gültig ist. <br/>
                      Die Gültigkeit des Anlageprofils wird durch Ihre Bestätigung um 3 Jahre verlängert.
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" className="m-1 bav" onClick={handleCloseDlg}>Abbrechen</Button>
                  <Button variant="contained" className="m-1 bav" onClick={handleReconfirm} autoFocus>
                      Bestätigen
                  </Button>
              </DialogActions>
             </Dialog>
          </>
          ) : ""}
      </>
  );
}
export default BAVButtonColumn;
